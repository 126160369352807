import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import SiteHeader from "containers/SiteHeader";
import useWindowSize from "hooks/useWindowResize";
import PrivacyPolicy from "containers/SupportPage/PrivacyPolicy";
import LegalAdvice from "containers/SupportPage/LegalAdvice";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/privacy-policy", exact: true, component: PrivacyPolicy },
  { path: "/legal-advice", exact: true, component: LegalAdvice }

  /*{ path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", component: PageHome2 },
  { path: "/home-3", component: PageHome3 },
  //
  { path: "/listing-experiences", component: ListingExperiencesPage }, 
  { path: "/listing-experiences-map", component: ListingExperiencesMapPage },
  { path: "/listing-experiences-detail", component: ListingExperiencesDetailPage },
  //
  {
    path: "/listing-experiences",
    component: ListingExperiencesPage,
  },
  {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  // { path: "/listing-car", component: ListingCarPage },
  // { path: "/listing-car-map", component: ListingCarMapPage },
  // { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  // { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  // { path: "/listing-real-estate", component: ListingRealEstatePage },
  //
  // { path: "/listing-flights", component: ListingFlightsPage },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  // { path: "/add-listing-1", component: PageAddListing1 },
  // { path: "/add-listing-2", component: PageAddListing2 },
  // { path: "/add-listing-3", component: PageAddListing3 },
  // { path: "/add-listing-4", component: PageAddListing4 },
  // { path: "/add-listing-5", component: PageAddListing5 },
  // { path: "/add-listing-6", component: PageAddListing6 },
  // { path: "/add-listing-7", component: PageAddListing7 },
  // { path: "/add-listing-8", component: PageAddListing8 },
  // { path: "/add-listing-9", component: PageAddListing9 },
  // { path: "/add-listing-10", component: PageAddListing10 },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },*/
];

const Routes = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  return (
    <BrowserRouter basename="/travelit">
      <ScrollToTop />
      <SiteHeader />

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>

      {/*WIN_WIDTH < 768 && <FooterNav />*/}
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
