import { Helmet } from "react-helmet";

const LegalAdvice = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Aviso Legal
          </h1>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p></p>
        <p>
          Bienvenido a Travelit. Por favor, lee atentamente los términos y condiciones 
          que rigen el uso de nuestra aplicación móvil.
        </p>
        <h3>Aceptación de Términos y Condiciones</h3>
        <p>
          Al descargar, instalar o utilizar nuestra aplicación, aceptas cumplir con 
          estos términos y condiciones. Si no estás de acuerdo con alguna parte de 
          estos términos, por favor, no utilices la aplicación.
        </p>
        <h3>Datos de Inicio de Sesión</h3>
        <p>
          Nuestra aplicación solo recopila información de inicio de sesión, incluyendo 
          tu nombre, correo electrónico y contraseña. Estos datos se utilizan 
          exclusivamente para proporcionarte acceso a nuestra plataforma y no 
          serán compartidos con terceros sin tu consentimiento explícito.
        </p>
        <h3>Responsabilidad del Usuario</h3>
        <p>
          Eres responsable de mantener la confidencialidad de tu información de 
          inicio de sesión. Te recomendamos encarecidamente no compartir tu contraseña 
          con terceros y tomar las precauciones necesarias para proteger tu cuenta.
        </p>
        <h3>Uso de la Aplicación</h3>
        <p>
          Nuestra aplicación está destinada únicamente para el propósito descrito y no 
          debe ser utilizada para actividades ilegales o inapropiadas. Nos reservamos 
          el derecho de suspender o cancelar cuentas que violen estos términos y condiciones.
        </p>
        <h3>Cambios en los Términos</h3>
        <p>
          Nos reservamos el derecho de modificar estos términos en cualquier momento. 
          Se te notificará sobre cambios significativos en los términos y se te pedirá 
          que aceptes los nuevos términos antes de continuar utilizando la aplicación.
        </p>
        <h3>Contacto</h3>
        <p>
          Si tienes alguna pregunta o inquietud sobre estos términos y condiciones, 
          contáctanos a través de travelit.help@gmail.com
        </p>
        <p></p>
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Travelit || Aviso Legal</title>
      </Helmet>
      {renderHeader()}
      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
      </div>
    </div>
  );
};

export default LegalAdvice;
