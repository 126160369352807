import React from "react";

const LogoTravelitSvg = () => {
  return (
    <svg
      className="w-full block dark:hidden"
      viewBox="0 0 150 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="layer1">
        <g
          id="g10638">
          <g
            aria-label="travelit"
            transform="matrix(7.0976177,0,0,7.0976177,-473.77964,-1475.749)"
            id="text13767"
            fill="currentColor">
            <path
              d="m 76.810856,212.17055 q 0.0635,0 0.130175,-0.01 0.06668,-0.01 0.10795,-0.0254 v 0.21272 q -0.04445,0.0222 -0.127,0.0349 -0.08255,0.0159 -0.15875,0.0159 -0.13335,0 -0.24765,-0.0444 -0.111125,-0.0476 -0.180975,-0.16192 -0.06985,-0.1143 -0.06985,-0.32068 v -0.9906 h -0.2413 v -0.13335 l 0.244475,-0.11112 0.111125,-0.36195 h 0.1651 v 0.39052 h 0.492125 v 0.2159 h -0.492125 v 0.98425 q 0,0.15558 0.07303,0.23178 0.0762,0.073 0.193675,0.073 z"
              id="path10518" />
            <path
              d="m 78.182456,210.63385 q 0.04762,0 0.1016,0.006 0.05397,0.003 0.09842,0.0127 l -0.03493,0.25718 q -0.04127,-0.01 -0.09207,-0.0159 -0.0508,-0.006 -0.09208,-0.006 -0.130175,0 -0.244475,0.073 -0.1143,0.0699 -0.18415,0.20002 -0.06668,0.127 -0.06668,0.29845 v 0.90805 h -0.2794 v -1.7018 h 0.2286 l 0.03175,0.31115 h 0.0127 q 0.07937,-0.1397 0.20955,-0.2413 0.13335,-0.1016 0.31115,-0.1016 z"
              id="path10520" />
            <path
              d="m 79.281005,210.63703 q 0.31115,0 0.460375,0.13652 0.149225,0.13653 0.149225,0.43498 v 1.15887 h -0.2032 l -0.05397,-0.2413 h -0.0127 q -0.111125,0.1397 -0.23495,0.20638 -0.123825,0.0667 -0.33655,0.0667 -0.231775,0 -0.384175,-0.12065 -0.1524,-0.12382 -0.1524,-0.38417 0,-0.254 0.200025,-0.39053 0.200025,-0.1397 0.61595,-0.1524 l 0.288925,-0.01 v -0.1016 q 0,-0.21273 -0.09207,-0.29528 -0.09208,-0.0825 -0.26035,-0.0825 -0.13335,0 -0.254,0.0413 -0.12065,0.0381 -0.225425,0.0889 l -0.08572,-0.20955 q 0.111125,-0.0603 0.263525,-0.1016 0.1524,-0.0444 0.3175,-0.0444 z m 0.333375,0.89852 -0.250825,0.01 q -0.3175,0.0127 -0.441325,0.1016 -0.12065,0.0889 -0.12065,0.25082 0,0.14288 0.08572,0.20955 0.0889,0.0667 0.225425,0.0667 0.212725,0 0.3556,-0.11748 0.14605,-0.12065 0.14605,-0.3683 z"
              id="path10522" />
            <path
              d="m 80.792308,212.3674 -0.644525,-1.7018 h 0.29845 l 0.36195,1.0033 q 0.0254,0.0699 0.05397,0.15558 0.02858,0.0857 0.0508,0.1651 0.02222,0.0794 0.03175,0.13017 h 0.0127 q 0.0095,-0.0508 0.03493,-0.13017 0.0254,-0.0825 0.05397,-0.1651 0.02858,-0.0857 0.05398,-0.15558 l 0.36195,-1.0033 h 0.29845 l -0.6477,1.7018 z"
              id="path10524" />
            <path
              d="m 82.687781,210.63385 q 0.2159,0 0.37465,0.0952 0.15875,0.0953 0.2413,0.26988 0.08572,0.17145 0.08572,0.40322 v 0.16828 h -1.165225 q 0.0063,0.28892 0.14605,0.44132 0.142875,0.14923 0.396875,0.14923 0.161925,0 0.28575,-0.0286 0.127,-0.0318 0.26035,-0.0889 v 0.24448 q -0.130175,0.0572 -0.257175,0.0825 -0.127,0.0286 -0.301625,0.0286 -0.244475,0 -0.428625,-0.0984 -0.18415,-0.0984 -0.288925,-0.2921 -0.1016,-0.19368 -0.1016,-0.47943 0,-0.2794 0.09208,-0.47942 0.09525,-0.20003 0.263525,-0.30798 0.17145,-0.10795 0.396875,-0.10795 z m -0.0032,0.2286 q -0.200025,0 -0.3175,0.13018 -0.1143,0.127 -0.136525,0.3556 h 0.866775 q -0.0032,-0.2159 -0.1016,-0.34925 -0.09843,-0.13653 -0.31115,-0.13653 z"
              id="path10526" />
            <path
              d="m 84.100653,212.3674 h -0.2794 v -2.413 h 0.2794 z"
              id="path10528" />
            <path
              d="m 84.783276,210.02743 q 0.0635,0 0.111125,0.0444 0.0508,0.0413 0.0508,0.13335 0,0.0921 -0.0508,0.13652 -0.04762,0.0413 -0.111125,0.0413 -0.06985,0 -0.117475,-0.0413 -0.04762,-0.0444 -0.04762,-0.13652 0,-0.0921 0.04762,-0.13335 0.04762,-0.0444 0.117475,-0.0444 z m 0.136525,0.63817 v 1.7018 h -0.2794 v -1.7018 z"
              id="path10530" />
            <path
              d="m 86.027874,212.17055 q 0.0635,0 0.130175,-0.01 0.06667,-0.01 0.10795,-0.0254 v 0.21272 q -0.04445,0.0222 -0.127,0.0349 -0.08255,0.0159 -0.15875,0.0159 -0.13335,0 -0.24765,-0.0444 -0.111125,-0.0476 -0.180975,-0.16192 -0.06985,-0.1143 -0.06985,-0.32068 v -0.9906 h -0.2413 v -0.13335 l 0.244475,-0.11112 0.111125,-0.36195 h 0.1651 v 0.39052 h 0.492125 v 0.2159 h -0.492125 v 0.98425 q 0,0.15558 0.07303,0.23178 0.0762,0.073 0.193675,0.073 z"
              id="path10532" />
          </g>
          <g
            id="g3427"
            transform="matrix(0.14920369,-0.21850079,0.21850079,0.14920369,27.994063,28.224482)">
            <path
              id="rect7824"
              fill="none"
              d="M 0,0 H 100 V 100 H 0 Z" />
          </g>
          <g
            id="g3427-8"
            transform="matrix(-0.2642817,-0.01263018,0.01263018,-0.2642817,40.785824,27.405242)">
            <path
              id="rect7818"
              fill="none"
              d="M 0,0 H 100 V 100 H 0 Z" />
          </g>
          <g
            id="g3427-0"
            transform="matrix(0.06001484,0.25768694,-0.25768694,0.06001484,35.639692,17.029362)">
            <path
              id="rect7812"
              fill="none"
              d="M 0,0 H 100 V 100 H 0 Z" />
          </g>
          <g
            id="g3427-8-5-8-7-7"
            transform="matrix(0.33438555,-0.09079306,0.09079306,0.33438555,10.099883,14.246958)">
            <path
              id="rect3416-3-7-4-1-4"
              fill="none"
              d="M 0,0 H 100 V 100 H 0 Z" />
          </g>
          <path
            id="path4616-3-1-7-5"
            fill="currentColor"
            d="M 33.441948,2.259981 A 23.025537,23.025537 0 0 0 10.416479,25.285442 23.025537,23.025537 0 0 0 33.441948,48.311432 23.025537,23.025537 0 0 0 56.467416,25.285442 23.025537,23.025537 0 0 0 33.441948,2.259981 Z M 21.644219,13.92955 c 0.168414,-0.02387 0.492229,0.04138 1.113627,0.397392 0,0 8.899271,4.75477 10.933183,9.18704 0,0 0.702563,0.815 1.639176,0.56069 0,0 3.824664,-1.21349 2.336808,2.16369 l 0.05116,0.51108 c 0,0 1.905464,0.57582 3.295406,1.99109 0,0 6.009333,4.09609 6.979419,7.0249 0,0 -0.11149,0.55478 -1.394747,-0.14625 0,0 -1.315276,0.79471 -2.375049,-1.01647 0,0 -1.166535,0.53551 -2.730066,-0.87644 0,0 -1.814038,0.40474 -2.811197,-1.33583 0,0 -2.483932,0.19354 -3.026172,-1.32085 0,0 -2.371784,-0.36178 -2.481503,-1.73116 l -1.506884,0.40927 c 0,0 0.388787,3.04264 -1.478463,4.86121 0,0 -0.448558,0.60325 -0.816488,-1.39578 0,0 -0.729556,0.37258 -1.363741,-1.64176 0,0 -2.67788,-1.3278 -1.492932,-1.95544 0,0 -2.079896,-0.0911 -1.804541,-1.17151 0,0 -3.145988,-0.15165 -2.300118,-1.8681 0,0 4.61617,-0.55356 6.702433,0.36639 l 0.628903,-0.73898 c 0,0 -1.180938,0.80174 -1.21543,-1.41851 0,0 -1.046273,0.65542 -1.340487,-1.87741 0,0 -1.606916,-0.12087 -1.820044,-1.54978 0,0 -1.956152,-1.0867 -1.799373,-2.44119 0,0 -1.228168,-0.978 -0.937927,-2.32492 0,0 -1.375323,-1.20057 -1.166337,-2.525429 0,0 0.01297,-0.113071 0.181384,-0.136943 z" />
          <g
            id="g3427-8-5-8-1-1"
            transform="matrix(0.33438555,-0.09079306,0.09079306,0.33438555,12.183022,13.106071)">
            <path
              id="rect3416-3-7-4-0-7"
              fill="none"
              d="M 0,0 H 100 V 100 H 0 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoTravelitSvg;
