import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SupportListProps {
  className?: string;
}

const socials: SocialType[] = [
  /*{ name: "TikTok", icon: "lab la-tiktok", href: "#" },*/
  { name: "Política de Privacidad", icon: "", href: "/privacy-policy" },
  { name: "Aviso Legal", icon: "", href: "/legal-advice" },
  /*{ name: "Youtube", icon: "lab la-youtube", href: "#" },*/
];

const SupportList: FC<SupportListProps> = ({ className = "space-y-2.5" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
      >
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SupportList ${className}`} data-nc-id="SupportList">
      {socials.map(renderItem)}
    </div>
  );
};

export default SupportList;
