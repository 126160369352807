import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialsList1Props {
  className?: string;
}

const socials: SocialType[] = [
  /*{ name: "TikTok", icon: "lab la-tiktok", href: "#" },*/
  { name: "Android - Google Play", icon: "lab la-android", href: "https://play.google.com/store/apps/details?id=com.developm8.travelit" },
  { name: "Apple - App Store", icon: "lab la-apple", href: "https://apps.apple.com/us/app/travelit/id6446145156" },
  /*{ name: "Youtube", icon: "lab la-youtube", href: "#" },*/
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
      >
        <i className={item.icon}></i>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
