import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";

import HIW1img from "images/HIW2-1.png";
import HIW2img from "images/HIW2-2.png";
import HIW3img from "images/HIW2-3.png";
import HIW1imgDark from "images/HIW2-1-dark.png";
import HIW2imgDark from "images/HIW2-2-dark.png";
import HIW3imgDark from "images/HIW2-3-dark.png";
import Heading from "components/Heading/Heading";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    //href: "/listing-stay",
    href: "#",
    name: "Tenerife",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://travelit.io/static/cities/es_islas-canarias.png",
  },
  {
    id: "2",
    //href: "/listing-stay",
    href: "#",
    name: "Sevilla",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://travelit.io/static/cities/es_sevilla.png",
  },
  {
    id: "3",
    //href: "/listing-stay",
    href: "#",
    name: "Madrid",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://travelit.io/static/cities/es_madrid.png",
  },
  {
    id: "4",
    //href: "/listing-stay",
    href: "#",
    name: "Barcelona",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://travelit.io/static/cities/es_barcelona.png",
  },
  {
    id: "5",
    //href: "/listing-stay",
    href: "#",
    name: "Valencia",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://travelit.io/static/cities/es_valencia.png",
  },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Sleep in a floating way",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Cool in the deep forest",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        />

        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>

        {/* SECTION */}
        <SectionHowItWork />

        <SectionHowItWork
          showHeader={false}
          data={[
            {
              id: 1,
              img: HIW1img,
              imgDark: HIW1imgDark,
              title: "Busqueda precisa",
              desc: "Tú nos indicas el lugar o lugares, fechas y actividades que te gusten y te mostraremos una planificación de qué ver, distribución por días e información sobre las actividades.",
            },
            {
              id: 2,
              img: HIW2img,
              imgDark: HIW2imgDark,
              title: "Nuestras experiencias",
              desc: "Te damos información de dónde se encuentra cada actividad, teniendo en cuenta el tiempo entre cada uno, e información sobre cada uno (déjate de buscar información en múltiples páginas diferentes).",
            },
            {
              id: 3,
              img: HIW3img,
              imgDark: HIW3imgDark,
              title: "Haz cambios y a explorar",
              desc: "Podrás añadir, quitar o modificar las actividades para que completes a tu gusto tu experiencia. Una vez listo, usa Travelit para ver la información de cada lugar, acceder fácilmente con Google Maps a la ruta hasta el siguiente punto, etc.",
            },
          ]}
        />

        <div>
          <Heading isCenter desc="Entra. Planifica. Viaja. Disfruta.">
            Descarga la aplicación en Android o iOS
          </Heading>
          <div className="relative flex flex-col items-center">
            <li className="flex items-center space-x-4">
              <ButtonPrimary onClick={() => {window.open("https://play.google.com/store/apps/details?id=com.developm8.travelit", "_blank")}} >
                <i className="lab la-android text-2xl"></i>
                <span>Google Play Console</span>
              </ButtonPrimary>
              <ButtonPrimary onClick={() => {window.open("https://apps.apple.com/us/app/travelit/id6446145156", "_blank")}} >
                <i className="lab la-apple text-2xl"></i>
                <span>Apple App Store</span>
              </ButtonPrimary>
            </li>
          </div>
        </div>

        {/* SECTION 1 */}
        {/*<div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places to stay that Chisfis recommends for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div>*/}

        {/* SECTION */}
        {/*<SectionSubscribe2 />*/}

        {/* SECTION */}
        {/*<div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div>*/}

        {/* SECTION */}
        {/*<SectionGridCategoryBox />*/}

        {/* SECTION */}
        {/*<div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>*/}

        {/* SECTION 1 */}
        {/*<SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        />*/}

        {/* SECTION */}
        {/*<SectionVideos />*/}

        {/* SECTION */}
        {/*<div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>*/}
      </div>
    </div>
  );
}

export default PageHome;
