import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Política de Privacidad
          </h1>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p></p>
        <p>
          Tu privacidad es importante para nosotros. Esta Política de Privacidad 
          describe cómo Travelit recopila, utiliza y protege la información que 
          proporcionas al utilizar nuestra aplicación móvil.
        </p>
        <h3>Datos Recopilados</h3>
        <p>
          Recopilamos información de inicio de sesión, que incluye tu nombre, dirección
          de correo electrónico y contraseña. Estos datos se utilizan exclusivamente para 
          facilitar el acceso y la seguridad de tu cuenta en nuestra aplicación.
        </p>
        <h3>Uso de la Información</h3>
        <p>
          La información de inicio de sesión se utiliza para identificarte y permitirte
          acceder a los servicios de nuestra aplicación. No compartiremos esta información 
          con terceros sin tu consentimiento, a menos que sea requerido por ley.
        </p>
        <h3>Seguridad de los Datos</h3>
        <p>
          Implementamos medidas de seguridad para proteger tus datos contra accesos no
          autorizados o divulgación. Sin embargo, no podemos garantizar la seguridad 
          absoluta de la información transmitida a través de Internet.
        </p>
        <h3>Cookies y Tecnologías Similares</h3>
        <p>
          Nuestra aplicación no utiliza cookies ni tecnologías similares para 
          recopilar información personal.
        </p>
        <h3>Cambios en la Política de Privacidad</h3>
        <p>
        Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier 
        momento. Te informaremos sobre cambios significativos y te pediremos que aceptes 
        los nuevos términos para continuar utilizando nuestra aplicación.
        </p>
        <h3>Contacto</h3>
        <p>
        Si tienes preguntas sobre nuestra Política de Privacidad, contáctanos a 
        través de travelit.help@gmail.com
        </p>
        <p></p>
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Travelit || Política de Privacidad</title>
      </Helmet>
      {renderHeader()}
      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
