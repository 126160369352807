import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
            Encuentra tu siguiente experiencia
          </h2>
          <p>
            <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
              Busca, encuentra y planea de forma muy sencilla tu siguiente viaje o experiencia
               a través de <b className="text-primary-500 travelit-bold">Travelit</b>.
            </span>
          </p>
          <p>
            <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
              Todo desde una única aplicación. En tu móvil. Contigo. Tanto para Android como para iOS.
            </span>
          </p>
          <p>
            <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
              <b className="text-primary-500 travelit-bold">Descarga Travelit para tu móvil</b>. Disponible para <b color="">Android</b> e <b style={{color: '#0071e3'}}>iOS</b>:
            </span>
          </p>
          <li className="flex items-center space-x-4">
            <ButtonPrimary onClick={() => {window.open("https://play.google.com/store/apps/details?id=com.developm8.travelit", "_blank")}} >
              <i className="lab la-android text-2xl"></i>
              <span>Google Play Console</span>
            </ButtonPrimary>
            <ButtonPrimary onClick={() => {window.open("https://apps.apple.com/us/app/travelit/id6446145156", "_blank")}} >
              <i className="lab la-apple text-2xl"></i>
              <span>Apple App Store</span>
            </ButtonPrimary>
          </li>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
